<template>
    <div class="card" v-if="documentosServicio.length > 0">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>Gestor documentos</h3>
        </div>
        <div class="card-body">
            <div class="row">

                <div class="col-md-6" style="display: grid;row-gap: 15px; height: fit-content;">
                    <div class="row" style="margin-left: 0;">
                        <div style="display: grid; width: 60%;">
                            <label for="documentos">Documento</label>
                            <select id="documentos" class="form-control" style="width: 90%;" v-model="documentoSeleccionado">
                                <option value="sin doc">Selecciona un documento</option>
                                <option v-for="documento in documentosServicio" :key="documento.id_documento" :value="documento.id_documento">{{ documento.nombre }}</option>
                            </select>
                        </div>
                        <div style="display: grid; align-items: end;">
                            <button class="btn  btn-light border-secondary" @click="generarDocumento">Generar</button>
                        </div>
                    </div>
                    <div v-if="generadosVisible" style="display: grid; row-gap: 15px;">                        
                        <div v-for="(separados) in Object.entries(docsSeparados)" :key="separados[0]">
                            <div class="card card-primary">
                                <div class="card-header p-3">
                                    <div ><span >Documentos de {{ separados[0] }}</span></div>
                                </div>
                                <div class="card-body">
                                    <div v-for="doc in separados[1]" :key="doc.id_documento_generado" class="card" style="border-left:6px solid #117a8b;">
                                        <div class="card-header p-1">
                                            <div class="row" style="gap: 15px; align-items: center; margin: 0;">
                                                    <span>{{ doc.fecha_generado }}</span>
                                                    <button class="btn btn-light" v-if=" botones[doc.id_documento_generado].includes('docx')" @click="descargar(doc.url_completa_descarga_docx)" style="color: DodgerBlue;" title="Descarga DOCX"><i class="fas fa-file-word"></i></button>
                                                    <button class="btn btn-light" v-if=" botones[doc.id_documento_generado].includes('excel')" @click="descargar(doc.url_completa_descarga_docx)" style="color: MediumSeaGreen;" title="Descarga XLSX"><i class="fas fa-file-excel"></i></button>
                                                    <button class="btn btn-light" v-if=" botones[doc.id_documento_generado].includes('pdf')" @click="descargar(doc.url_completa_descarga_pdf)" style="color: FireBrick;" title="Descarga PDF"><i class="fas fa-file-pdf"></i></button>
                                                    <button class="btn btn-light" v-if=" botones[doc.id_documento_generado].includes('pdff')" @click="descargar(doc.url_completa_descarga_pdf_firmado)" style="color: Crimson;" title="Descarga PDF Firmado"><i class="fas fa-file-signature"></i></button>
                                                    <button class="btn btn-light" v-if=" botones[doc.id_documento_generado].includes('png')" @click="descargar(doc.url_completa_descarga_png)" style="color: Teal;" title="Descarga JPG"><i class="fas fa-file-contract"></i></button>
                                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="visibleForm[doc.id_documento_generado] = !visibleForm[doc.id_documento_generado]">Enviar</button>
                                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="eliminarDoc(doc.id_documento_generado)">Eliminar</button>
                                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="visibleEnvios[doc.id_documento_generado] = !visibleEnvios[doc.id_documento_generado]">Envíos</button>
                                            </div>
                                        </div>
                                        <div class="card-body" v-if="docsEnviados[doc.id_documento_generado].length>0 || visibleForm[doc.id_documento_generado]">
                                            <div v-if="visibleForm[doc.id_documento_generado]" style="width: 80%;">
                                                <div class="form-group">
                                                        <label for="envio">Tipo envio</label>
                                                        <div>
                                                            <select class="form-control" v-model="envioSeleccionado[doc.id_documento_generado]" id="envio">
                                                                <option value="email">Email</option>
                                                                <option value="pap" v-if="pap">PAP</option>
                                                            </select>
                                                        </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="doc">Tipo Documento</label>
                                                    <div>
                                                        <select class="form-control" v-model="docSeleccionado[doc.id_documento_generado]" id="doc">
                                                            <option value="pdf">PDF</option>
                                                            <option :value="extensiones[doc.id_documento_generado]">{{ extensiones[doc.id_documento_generado] }}</option>
                                                        </select>
                                                    </div>                                                    
                                                </div>
                                                <div class="form-group" v-if="pap">
                                                    <label for="docPAP">Tipo documento PAP</label>
                                                    <div>
                                                        <select class="form-control" v-model="PAPSeleccionado[doc.id_documento_generado]" id="docPAP">
                                                            <option value="">Seleccione</option>
                                                            <option  v-for="papoption in tipo_doc_pap[doc.id_documento_generado]" :value="papoption.valor" :key="papoption">{{ papoption.texto }}</option>
                                                        </select>
                                                    </div>                                                      
                                                </div>
                                                <div class="form-group">
                                                    <label for="email">Email</label>
                                                    <div style="display: grid; row-gap: 5px;">
                                                        <select class="form-control" v-model="emailSeleccionado[doc.id_documento_generado]" id="email">
                                                            <option value="">Seleccione</option>
                                                            <option  v-for="email in emails[doc.id_documento_generado]" :value="email.email" :key="email.email">{{ email.nombre }}</option>
                                                        </select>
                                                        <input class="form-control" v-model="emailIntroducido[doc.id_documento_generado]" type="text" placeholder="Introducir email">
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="asunto">Asunto</label>
                                                    <input class="form-control" v-model="asuntoSeleccionado[doc.id_documento_generado]" id="asunto" type="text">
                                                </div>
                                                <div class="form-group">
                                                    <label for="texto">Texto</label>
                                                    <div>
                                                        <textarea class="form-control"  id="texto" v-model="ta[doc.id_documento_generado]"></textarea>
                                                    </div>
                                                </div>
                                                <div class="row" style="column-gap: 15px; margin: 0; justify-content: end;">
                                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="guardarForm(doc.id_documento_generado)">Enviar</button>
                                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="visibleForm[doc.id_documento_generado]=false">Cerrar</button>
                                                </div>
                                            </div>
                                            <hr v-if="docsEnviados[doc.id_documento_generado].length>0 && visibleForm[doc.id_documento_generado] && visibleEnvios[doc.id_documento_generado]"/>
                                            <div  v-if="docsEnviados[doc.id_documento_generado].length>0 && visibleEnvios[doc.id_documento_generado]" style="display: grid;margin-top: 15px;">
                                                <table class="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Fecha envío</th>
                                                            <th>Destinatario</th>
                                                            <th>Usuario</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-for="(docEnv, index) in docsEnviados[doc.id_documento_generado]" :key="docEnv.id_documento_envio">
                                                        <tr v-if="index%2==0" style="background-color: rgba(0, 0, 0, .05)">
                                                            <td>{{ docEnv.fecha_envio }}</td>
                                                            <td>{{ docEnv.destinatario_envio }}</td>
                                                            <td><i class="fas fa-info-circle" @click="infoExtraVisible[docEnv.id_documento_envio]=!infoExtraVisible[docEnv.id_documento_envio]"></i>{{ docEnv.usuario }}</td>
                                                            
                                                        </tr>
                                                        <tr v-else style="background-color: white;">
                                                            <td>{{ docEnv.fecha_envio }}</td>
                                                            <td>{{ docEnv.destinatario_envio }}</td>
                                                            <td><i class="fas fa-info-circle" @click="infoExtraVisible[docEnv.id_documento_envio]=!infoExtraVisible[docEnv.id_documento_envio]"></i>{{ docEnv.usuario }}</td>
                                                        </tr>
                                                        <tr   v-if="infoExtraVisible[docEnv.id_documento_envio] && index%2==0" style="background-color: rgba(0, 0, 0, .05)">
                                                            <td colspan="3">Texto enviado: {{ docEnv.texto_envio }}</td>
                                                        </tr>
                                                        <tr  v-else-if="infoExtraVisible[docEnv.id_documento_envio]" style="background-color: white;">
                                                            <td colspan="3">Texto enviado: {{ docEnv.texto_envio }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-sm btn-light border-secondary" @click="visible=true">Datos adicionales</button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row" style="justify-content: space-around;row-gap: 10px;" v-if="imagenes">
                        <div v-for="img in Object.entries(imagenes)" :key="img[1].id_documento">
                            <div style="display: grid; row-gap: 10px;">
                                <Image v-if="img[1].propiedad_cliente != 0 && urlglobal != 'null.pwgs.app'"    :src='img[1].url_completa_descarga' style="border: 5px solid goldenrod;" width="100" preview :alt="img[1].descripcion" />
                                <Image v-else-if="urlglobal != 'null.pwgs.app'"  :src='img[1].url_completa_descarga'  width="100" preview :alt="img[1].descripcion" />
                                <Image v-if="img[1].propiedad_cliente != 0 && urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + img[1].documento + "&servicio=" +id' style="border: 5px solid goldenrod;" width="100" preview :alt="img[1].descripcion" />
                                <Image v-else-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + img[1].documento + "&servicio=" +id' width="100" preview :alt="img[1].descripcion" />
                                <input type="checkbox" v-model="activado[img[1].id_documento]">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        <gestordocumentos_datosadicionales :gestion="gestion" :visible="visible" :idservicio="id" @update:visible="cerrarModal"/>
    </div>  
</template>
<script>
import Image from 'primevue/image';
import { PwgsApi } from '../../../../../services/PwgsApi';
import gestordocumentos_datosadicionales from './gestordocumentos_datosadicionales.vue';
export default ({
    props: ['id', 'servicio'],
    data() {
        return {
            gestion:[],
            infoExtraVisible:[],
            generadosVisible: false,
            docsEnviados:[],
            ta:[],
            asuntoSeleccionado:[],
            codigo:'',
            emails:[],
            tipo_doc_pap:[],
            envioSeleccionado:[],
            docSeleccionado:[],
            docsSeparados:[],
            documentosServicio: [],
            documentoSeleccionado: "sin doc",
            PAPSeleccionado:[],
            visible:false,
            visibleForm:[],
            visibleEnvios:[],
            imagenes: [],
            activado:[],
            urlglobal: '',
            docsGenerados:[],
            botones:[[]],
            pap:false,
            extensiones:[],
            emailSeleccionado:[],
            emailIntroducido:[],
        }
    },
    components: {
        gestordocumentos_datosadicionales,
        'Image': Image,
    },
        setup() {
        return;
    },
    methods: {
        async guardarForm(idDoc){
            if(this.emailIntroducido[idDoc]==''){
                alert('Introduce un email');
                return;
            }
            if(this.ta[idDoc]==''){
                alert('Introduce un texto');
                return;
            }
            const api = new PwgsApi();
            var body = {'tipo_envio': this.envioSeleccionado[idDoc], 'formato':this.docSeleccionado[idDoc],
             'email':this.emailIntroducido[idDoc],'texto':this.ta[idDoc],'asunto':this.asuntoSeleccionado[idDoc]};
            if(this.pap){
                body.pap = this.PAPSeleccionado[idDoc];
            }
            const resp = await api.put('servicios/'+this.id+'/enviar-gestion-doc/'+idDoc,body);
            console.log('enviar doc', resp);
            this.generadosVisible = false;
            this.obtenerGenerados();
        },
        async eliminarDoc(idDoc){
            const api = new PwgsApi();
            const resp = await api.delete('/servicios/'+this.id+'/gestion-doc/'+idDoc);
            console.log('eliminar doc', resp);
            this.generadosVisible=false;
            this.obtenerGenerados();
        },
        descargar(link){
            //let urlglobal = localStorage.getItem('direccion');
            window.open(link, "width=800, height=600");
        },  
        async cargarDocumentosServicio() {
            const api = new PwgsApi();
            const resp = await api.get('/servicios/'+this.id+'/gestion-doc?tipo=servicios');
            this.gestion = resp;
            this.documentosServicio = resp.gestiondoc.servicios;
        },
        
        async cargarImagenes(){   
            const api = new PwgsApi();
            const resp = await api.get('/servicios/'+this.id+'/documentos');
            for(let imagen of Object.entries(resp)){
                if(imagen[1].image == '1'){
                    var id = imagen[0];
                    this.imagenes[id]=imagen[1];
                    this.activado[id]=false;
                }
            }
        },
        async obtenerGenerados(){
            try{
                this.docsGenerados = [];
                this.docsSeparados = [];
                this.docsEnviados = [];
                const api = new PwgsApi();
                const resp = await api.get('/servicios/'+this.id+'/gestion-doc-generados');
                this.docsGenerados = resp.gestiondoc_generados;
                for(let docs of this.docsGenerados){
                    this.docsEnviados[docs.id_documento_generado] = docs.envios;
                    var nombre = docs.datos_documento.nombre;
                    if(this.docsSeparados[nombre]){
                        this.docsSeparados[nombre].push(docs);
                    }else{
                        this.docsSeparados[nombre]=[docs];
                    }
                    if (!this.botones[docs.id_documento_generado]) {
                        this.botones[docs.id_documento_generado] = [];
                    }
                    this.infoExtraVisible[docs.id_documento_generado] = false;
                    this.visibleForm[docs.id_documento_generado] = false;
                    this.visibleEnvios[docs.id_documento_generado] = false;
                    this.extensiones[docs.id_documento_generado] = docs.datos_documento.extension;
                    this.tipo_doc_pap[docs.id_documento_generado] = docs.tipo_doc_pap;
                    this.envioSeleccionado[docs.id_documento_generado]='email';
                    this.docSeleccionado[docs.id_documento_generado]='pdf';
                    this.PAPSeleccionado[docs.id_documento_generado]='';
                    this.emailSeleccionado[docs.id_documento_generado]='';
                    this.emails[docs.id_documento_generado]=docs.emails;
                    this.emailIntroducido[docs.id_documento_generado]='';
                    this.ta[docs.id_documento_generado]='';
                    this.asuntoSeleccionado[docs.id_documento_generado]=this.codigo;
                    
                    if(docs.ruta_generado){
                        const ruta = docs.ruta_generado.toLowerCase();
                        if(ruta.includes('.docx')){
                            this.botones[docs.id_documento_generado].push('docx')
                        }else{
                            this.botones[docs.id_documento_generado].push('excel')
                        }
                    }
                    if(docs.ruta_pdf_generado){
                        this.botones[docs.id_documento_generado].push('pdf')
                    }
                    if(docs.ruta_png_generado){
                        this.botones[docs.id_documento_generado].push('png')
                    }
                    if(docs.ruta_pdf_generado_firmado){
                        this.botones[docs.id_documento_generado].push('pdff')
                    }
                }

                this.generadosVisible = true;
            }catch(e){
                console.log('sin docs generados',e)
            }
        },
        comprobarCodigo(){
            if(this.servicio){
                this.codigo='Exp.'+this.servicio.codigo_original;
                if(this.servicio.compania.sistema=="P.A.P"){
                    this.pap=true;
                }
                this.obtenerGenerados();            
            }
        },
        async generarDocumento(){
            if(this.documentoSeleccionado != 'sin doc'){
                const api = new PwgsApi();
                var ids = [];
                for(let img in this.imagenes){
                    var id = this.imagenes[img].id_documento;
                    if(this.activado[id]){
                        ids.push(id);
                    }
                }
                var body = {};
                if(this.ids !=[]){
                    body = {'seccion': 'servicios', 'id_seccion':this.id, 'id_documento':this.documentoSeleccionado, 'ids_documentos':ids};
                }else{
                    body = {'seccion': 'servicios', 'id_seccion':this.id, 'id_documento':this.documentoSeleccionado};
                }
                const resp = await api.post('/servicios/'+this.id+'/generar-gestion-doc', body);
                console.log('documentoGenerado',resp);
                this.generadosVisible = false;
                this.obtenerGenerados();
            }else{
                alert('Selecciona un documento');
            }
        },
        cerrarModal() {
            this.visible = false;
        }
    },
    mounted() {
        console.log('monta1');
        this.documentosServicio = [];
        this.docsGenerados=[];
        this.docsSeparados= [];
        this.botones= [];
        this.pap = false;
        this.codigo = '';
        this.imagenes = [];
        this.generadosVisible = false;
        this.cargarDocumentosServicio();
        this.cargarImagenes();
        this.comprobarCodigo();
        this.urlglobal = localStorage.getItem("direccion");
    },
    watch:{
        id(){
            this.documentosServicio = [];
            this.docsGenerados=[];
            this.docsSeparados= [];
            this.botones= [];
            this.pap = false;
            this.codigo = '';
            this.imagenes = [];
            this.generadosVisible = false
            this.cargarDocumentosServicio();
            this.cargarImagenes();
            this.urlglobal = localStorage.getItem("direccion");
        },
        servicio(){
            this.comprobarCodigo();
        },
        emailSeleccionado:{
            handler(newVal) {
                Object.keys(newVal).forEach(idDoc => {
                    this.emailIntroducido[idDoc] = newVal[idDoc];
                });
            },
            deep: true
        }
    }
})
</script>
<style>
.fg{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
}
</style>