<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>Documentos</h3>
        </div>
        <div class="card-body">
            <TabView :scrollable="true">
                <TabPanel header="Partes de Visita" v-if="archivosPartes && archivosPartes.length>0">
                    <h4 style="text-align:center">{{ $t('general.adjuntararchivosencorreo') }}</h4>
                    <div class="row">
                        <div class="col-sm-4" v-for="archivos in archivosPartes" :key="archivos" style="text-align: center;">
                            <i class="fas fa-file" style="font-size:50px;" @click="descargar(archivos)"></i>
                            <div style="display: flex; gap: 5px;">
                                <input type="checkbox" v-model="archivos.marcado" @change="marcado(archivos)">
                                <span style="font-weight: bold;">{{ archivos.fecha_visita }}</span><p></p>
                                <span v-if="archivos.descripcion" class="badge bg-secondary">{{ archivos.descripcion }}</span>
                            </div>
                        </div>
                    </div>                    
                </TabPanel>
                <TabPanel header="Informes" v-if="archivosInformes && archivosInformes.length>0">
                    <h4 style="text-align:center">{{ $t('general.adjuntararchivosencorreo') }}</h4>
                    <div class="row">
                        <div class="col-sm-4" v-for="archivos in archivosInformes" :key="archivos" style="text-align: center;">
                            <i class="fas fa-file" style="font-size:50px;" @click="descargar(archivos)"></i>
                            <div style="display: flex; gap: 5px;">
                                <input type="checkbox" v-model="archivos.marcado" @change="marcado(archivos)">
                                <span style="font-weight: bold;">{{ archivos.fecha }}</span><p></p>
                                <span v-if="archivos.descripcion" class="badge bg-secondary">{{ archivos.descripcion }}</span>
                            </div>
                        </div>
                    </div>                    
                </TabPanel>
                <TabPanel header="Presupuestos" v-if="archivosPresupuestos && archivosPresupuestos.length>0">
                    <h4 style="text-align:center">{{ $t('general.adjuntararchivosencorreo') }}</h4>
                    <div class="row">
                        <div class="col-sm-4" v-for="archivos in archivosPresupuestos" :key="archivos" style="text-align: center;">
                            <i class="fas fa-file" style="font-size:50px;" @click="descargar(archivos)"></i>
                            <div style="display: flex; gap: 5px;">
                                <input type="checkbox" v-model="archivos.marcado" @change="marcado(archivos)">
                                <span style="font-weight: bold;">{{ archivos.numero }}</span><p></p>                                        
                                <span v-if="archivos.descripcion" class="badge bg-secondary">{{ archivos.descripcion }}</span>

                            </div>
                        </div>
                    </div>                    
                </TabPanel>
                <TabPanel header="Facturas" v-if="archivosFacturas && archivosFacturas.length>0">
                    <h4 style="text-align:center">{{ $t('general.adjuntararchivosencorreo') }}</h4>
                    <div class="row">
                        <div class="col-sm-4" v-for="archivos in archivosFacturas" :key="archivos" style="text-align: center;">
                            <i class="fas fa-file" style="font-size:50px;" @click="descargar(archivos)"></i>
                            <div style="display: flex; gap: 5px;">
                                <input type="checkbox" v-model="archivos.marcado" @change="marcado(archivos)">
                                <span style="font-weight: bold;">{{ archivos.numero }}</span><p></p>
                                <span v-if="archivos.descripcion" class="badge bg-secondary">{{ archivos.descripcion }}</span>
                            </div>
                        </div>
                    </div>                    
                </TabPanel>
            </TabView>            
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi'; 
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
export default ({
    props: ['id','servicio'],
    data() {
        return {
        archivosFacturas:[],
        archivosPresupuestos:[],
        archivosInformes:[],
        archivosPartes:[],
        archivosSeleccionados:[],
        }
    },
    setup() {
        return;
    },
    components: {
        TabPanel,TabView
    },
    methods: {
        reset(){
            this.archivosSeleccionados = [];
            this.archivosFacturas=[];
            this.archivosPresupuestos=[];
            this.archivosInformes=[];
            this.archivosPartes=[];
            this.documentosServicio();
        },
        marcado(archivo){
            if(archivo.marcado){
                this.archivosSeleccionados.push(archivo.url_completa_descarga);
            }else{
                let index = this.archivosSeleccionados.indexOf(archivo.url_completa_descarga);
                if(index>-1){
                    this.archivosSeleccionados.splice(index, 1);
                }  else{
                    console.error('No se encontró el archivo en la lista seleccionados');
                }
            }
            this.$emit('archivoSeleccionado', this.archivosSeleccionados);
        },  
        descargar(archivo){
          //  let direccionglobal = localStorage.getItem('direccion');
            let auxurlpdf = archivo.url_completa_descarga;
            window.open(auxurlpdf,'_blank')
        },
        async documentosServicio(){
            const api = new PwgsApi();
            const resp = await api.get('servicios/'+this.id+'/documentos/clasificados');
            this.archivosInformes = resp.informe_fotografico;
            if(this.archivosInformes){
                this.archivosInformes.forEach(archivo => archivo.marcado = false);
            }
            this.archivosPresupuestos = resp.presupuestos;
            console.log('archivosPresupuestos', this.archivosPresupuestos);
            if(this.archivosPresupuestos){
                this.archivosPresupuestos.forEach(archivo => archivo.marcado = false);
            }
            this.archivosFacturas = resp.facturas;
            if(this.archivosFacturas){
                this.archivosFacturas.forEach(archivo => archivo.marcado = false);
            }
            this.archivosPartes = resp.partes;
            if(this.archivosPartes){
                this.archivosPartes.forEach(archivo => archivo.marcado = false);
            }
        },
    },
    mounted() {
        this.documentosServicio();
    },
    watch: {
        id() {  
            this.documentosServicio();
        },
        servicio(){
            console.log('servss', this.servicio);
            this.documentosServicio();
        },
        visitas(){
            console.log('archvs', this.visitas);
        }
    }
})
</script>
<style>
.card-header::after{
    display: none;
}
</style>